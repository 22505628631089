.info-screen-overlay {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  z-index: 2;
  pointer-events: none;

  padding-bottom: 2em;
}

.info-screen-container {
  background:  rgba(23,27,38, .87);
  height: 100%;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2em 4em;
}

.info-screen-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-screen-content__title {
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-family: 'nickainleynormal';

  font-size: 36px;
  line-height: 48px;
  font-weight: bold;
  color: white;

  margin: 0;
}

@media screen and (min-width: 800px) and (min-height: 800px) {
  .info-screen-content__title {
    font-size: 54px;
    line-height: 74px;
  }
}

@media screen and (min-height: 900px) {
  .info-screen-content__title {
    margin: .5em;
  }
  .info-screen-content__screenshot {
    margin-bottom: 2em;
  }
}

.info-screen-content__screenshot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
}

.info-screen-content__screenshot img {
  margin: 0 2em;
  width: 40%;
  max-width: 300px;
}

.info-screen-content__text {
  color: #ffffff;
  font-family: 'Effra Medium';
  font-size: 1em;

  margin: 0 1em;

  width: 50%;
}

.info-screen-cancel {
  align-self: flex-end;
  user-select: none;
  pointer-events: auto;
  cursor: pointer;
  z-index: 1;

  fill: white;
  background: none;
  border: none;
  margin: 0;
  margin-top: 1em;
  font-weight: bold;
  font-size: 1em;
}

.info-screen-button {
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;

  height: 48px;
  width: 200px;
  border: none;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  color: rgba(23, 27, 38, 1);
  font-family: 'Effra Medium';
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;

  cursor: pointer;
  pointer-events: auto;
  z-index: 2;
}
