.scene--fallback,
.scene {
  position: absolute;
  top: 0;
  left: 0;
}

.scene--fallback,
.scene {
  background: linear-gradient(180deg, rgba(0,0,0,1) -50%, rgba(0,0,0,0) 46.65%);
  background-color: rgba(38,44,62,1);
  width: 100%;
  height: 100%;
  z-index: 0;
}

.scene--fallback {
  background: url(https://placekitten.com/480/853) no-repeat center bottom fixed;
}

@media screen and (min-width: 800px) {
  .scene--fallback {
    background: url(https://placekitten.com/1366/768) no-repeat center bottom fixed;
  }
}

.unsupported-message {
  position: absolute;
  left: 50%;
  right: 50%;
  width: 100%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
  line-height: 48px;
  font-weight: normal;
  font-family: 'Effra Medium';
}

.tooltip {
  background: #1f8bbc;
  font-family: 'Effra Medium';
  color: white;

  position: absolute;
  bottom: 0;

  width: 100%;
  z-index: 3;
}
