@font-face {
  font-family: 'nickainleynormal';
  src: url('nickainley-webfont.woff') format('woff2'),
  url('nickainley-webfont.woff2') format('woff');
}

@font-face {
  font-family: 'Effra Medium';
  src: url('effra-medium-webfont.woff2') format('woff2'),
  url('effra-medium-webfont.woff') format('woff');
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, rgba(0,0,0,1) -50%, rgba(0,0,0,0) 46.65%);
  background-color: rgba(38,44,62,1);
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: sans-serif;
  text-align: center;
  position: relative;

  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;
}

.button {
  background: black;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  min-height: 3em;
  min-width: 5em;
  text-transform: initial;
  text-transform: uppercase;
}

.button:hover {
  background: gray;
}

*:focus{
  outline-color: gray;
}
