.greating-card-dialog {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  z-index: 2;
  pointer-events: none;
}

.greeting {
  width: 90%;
  margin: 2em;

  color: white;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
  line-height: 48px;
  font-weight: normal;
  font-family: 'nickainleynormal';
  user-select: none;
  z-index: 2;
}

.logo {
  position: absolute;
  right: 0;
  margin: 1em;

  cursor: pointer;
  pointer-events: auto;
}

.logo svg {
  fill: white;
  height: 24px;
  width: 72.81px;
}

.message {
  line-height: 64px;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
  user-select: none;
}
.message--default {
  font-weight: bold;
}

.greeting h1 {
  font-size: 36px;
  margin-bottom: 0;
}
.greeting h2 {
  font-size: 24px;
  font-weight: normal;
  margin: 0;
}
.greeting p {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .greeting h1 {
    font-size: 68px;
    line-height: 74px;
  }
}

.launch-ar-button {
  box-sizing: border-box;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;

  height: 48px;
  width: 210px;
  border: none;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  color: rgba(23, 27, 38, 1);
  font-family: 'Effra Medium';
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;

  cursor: pointer;
  pointer-events: auto;
  z-index: 2;
}
